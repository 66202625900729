import enLocal from 'element-ui/lib/locale/lang/en'
import ac_en from "./activity/ac_en";
import enPoer from './languagePoer/en'
const en = {
    400000: 'Academy',
    400001: 'News',
    400002: 'Data',
    400003: 'Videos',
    400004: 'Learn',
    400005: 'Log In',
    400006: 'Register',
    400007: 'Wallets',
    400008: 'Wallet overview',
    400009: 'Main Market Account',

    400010: 'P2P Account',
    400011: 'Futures',
    400012: 'Wallet History',
    400013: 'Main Market Order',
    400014: 'Futures Order',
    400015: 'Overview',
    400016: 'Identity Verification',
    400017: 'Account security',
    400018: 'Referral',
    400019: 'Preferences',

    400020: 'Voucher Center',
    400021: 'Sign out',
    400022: '<em class="normal">{num}</em> pending notifications',
    400023: 'Clear all',
    400024: 'View More',
    400025: 'No records found',
    400026: 'Scan to download the App',
    400027: 'More Download Options',
    400028: 'Notification Details',
    400029: 'I understand',

    400030: 'P2P',
    400031: 'Markets',
    400032: 'Spot',
    400033: 'Futures',
    400034: 'Super Start',
    400035: 'Address does not exist',
    400036: 'The most secure digital asset trading platform of the world！',
    400037: 'About Us',
    400038: 'Terms',
    400039: 'Privacy',

    400040: 'Announcements',
    400041: 'Academy',
    400042: 'Beginners Center',
    400043: 'Spot Trading',
    400044: 'Futures',
    400045: 'FAQ',
    400046: 'Service',
    400047: 'Fees',
    400048: 'Rules',
    400049: 'Listing Application',

    400050: 'Referral',
    400051: 'Contact us',
    400052: 'Help Center',
    400053: 'Submit a Request',
    400054: 'Business Contacts',
    400055: 'Institutional Cooperation',
    400056: 'Language and Region',
    400057: 'Currency',
    400058: 'Select language and region',
    400059: 'Select exchange rate',

    400060: 'Transfer',
    400061: 'SuperEx internal transfers do not charge fees',
    400062: 'From',
    400063: 'To',
    400064: 'Pair',
    400065: 'Fee Discount',
    400066: 'Cross',
    400067: 'Isolated',
    400068: 'Split',
    400069: 'Balance',

    400070: 'Currency',
    400071: 'Search',
    400072: 'Amount',
    400073: 'Available',
    400074: 'All',
    400075: 'Confirm Transfer',
    400076: 'Open',
    400077: 'Try Later',
    400078: 'Open Futures Account',
    400079: 'You haven’t opened futures account yet, so transfer is temporarily unavailable.',

    400080: 'Resend in {timeout}s',
    400081: 'Send Code',
    400082: 'All cryptocurrency',
    400083: 'All fiat currencies',
    400084: 'All status',
    400085: 'Optional Area',
    400086: 'Third-Party',
    400087: 'Guideline',
    400088: 'Orders',
    400089: 'P2P Merchant Center',

    400090: 'P2P User Center',
    400091: 'Payment Method',
    400092: 'Post new ad',
    400093: 'My Ads',
    400094: 'More',
    400095: 'Apply',
    400096: 'Cancel',
    400097: 'You are temporarily unable to post ads',
    400098: 'Apply to become an advertiser first',
    400099: 'Get Verified',

    400100: 'Unverified',
    400101: 'You have not completed identity verification',
    400102: 'For the security of your account with other trading users, please complete identity verification first',
    400103: 'Set nickname',
    400104: 'Set your account nickname. It is recommended not to use your real name. The nickname length is 2~10 characters.',
    400105: 'Sorry, you cannot apply to become a merchant at this time.',
    400106: 'Set up successful',
    400107: 'Advantages of SuperEx P2P',
    400108: '0 Fee',
    400109: 'Trade Bitcoin and other cryptocurrencies easily and quickly with zero commission fees on the order side on SuperEx P2P!',

    400110: 'Flexible Payment Methods',
    400111: 'SuperEx P2P allows sellers to freely choose payment methods.',
    400112: 'Trade At Better Prices',
    400113: 'SuperEx P2P allows users to set their own prices and choose their preferred price to buy and sell cryptocurrency.',
    400114: 'Securely trade cryptocurrencies',
    400115: 'SuperEx P2P adopts T+n cash withdrawal mechanism, strictly manages merchants, and screens suspicious transactions with risk control big data to ensure the safety of platform assets and users can trade safely.',
    400116: '{amount} Volume',
    400117: '{num}% Transaction Rate',
    400118: 'Price',
    400119: 'Time limit for payment',

    400120: 'Seller payment method',
    400121: 'Transaction Terms',
    400122: 'I will pay',
    400123: 'I will receive',
    400124: 'I want to sell',
    400125: 'Payment Method',
    400126: 'Set up collection method',
    400127: 'Add Payment Method',
    400128: 'Reference unit price {price}',
    400129: 'Buy',

    400130: 'Sell',
    400131: 'In order to protect the security of funds, there will be a {limit} withdrawal limit for purchased assets',
    400132: 'Learn more',
    400133: 'OK',
    400134: 'According to the country of your certificate and the operating specifications of the platform, please switch to your local currency for transactions',
    400135: 'Buy limit: {min}-{max} {fiat}',
    400136: 'Sell limit: {min}-{max} {fiat}',
    400137: 'Advertisers',
    400138: 'Limit / Quantity',
    400139: 'Trade',

    400140: 'Amount',
    400141: 'Limit',
    400142: 'Process',
    400143: 'OK',
    400144: 'Before you start trading, please complete the identity verification',
    400145: 'Your order cancellations today have exceeded {num} times, and you cannot continue trading. Please try again after {time}',
    400146: 'You have reached the maximum number of pending orders ({num}), please complete the order in progress first.',
    400147: 'This advertiser has reached the maximum number of pending orders, please operate later or try another ad.',
    400148: 'You have not met the requirements of the advertiser, please try another advertisement.',
    400149: 'Select',

    400150: 'How P2P Works',
    400151: 'Buy Crypto',
    400152: 'Sell Crypto',
    400153: 'Place Your Order',
    400154: 'After order is placed, the seller\'s assets for that transaction will be managed by SuperEx P2P.',
    400155: 'Payment to the seller',
    400156: 'Send money to the seller via the payment method in the transaction information and click "Paid, Notify Seller" on SuperEx P2P after completing the transfer.',
    400157: 'Get your cryptocurrency',
    400158: 'Once the seller confirms receipt of payment, the custodial crypto will be released to you.',
    400159: 'Confirm Payment',

    400160: 'Check the transaction history in your account and make sure you received the payment from the buyer.',
    400161: 'Send Cryptocurrency',
    400162: 'Send cryptocurrency to buyers on SuperEx P2P after confirming receipt of payment.',
    400163: 'Amount',
    400164: 'Enter amount',
    400165: 'Fiat',
    400166: 'All Payment Methods',
    400167: 'Ad ID',
    400168: 'Confirm',
    400169: 'Are you sure you want to remove the ad?',

    400170: '1. Users who remove ads will not be able to trade with you through ads',
    400171: '2. Removal of advertisements does not affect ongoing orders',
    400172: 'Are you sure you want to delete this ad?',
    400173: 'Edit',
    400174: 'Delete',
    400175: 'Added',
    400176: 'Removed',
    400177: 'Created',
    400178: 'Minimum limit',
    400179: 'Remaining Amount/Order Amount',

    400180: 'Order Price',
    400181: 'Type',
    400182: 'Listing Successful',
    400183: 'All types',
    400184: 'Status',
    400185: 'Ad ID/Currency/Date',
    400186: 'Operate',
    400187: 'According to the operating specifications of the country or region where your SuperEx identity is certified and the platform, please choose the supported legal currency for transaction.',
    400188: 'Restricted',
    400189: 'Deleted',

    400190: 'Delisted Successfully',
    400191: 'Post Ads',
    400192: 'Edit Ad',
    400193: 'Fixed Price',
    400194: 'Floating Price',
    400195: 'Price Variation Percentage',
    400196: 'Enter',
    400197: 'Trading Price',
    400198: 'Market Reference Price',
    400199: 'Setting a floating percentage allows you to buy and sell at a percentage above or below the actual market reference price',

    400200: 'Fixed prices do not change with market fluctuations',
    400201: 'Trading Restrictions',
    400202: 'Enter the transaction volume',
    400203: 'Number of trades',
    400204: 'Minimum single limit',
    400205: 'Enter the minimum single transaction limit',
    400206: 'Maximum single limit',
    400207: 'Enter the maximum single transaction limit',
    400208: 'Max',
    400209: 'Ad requires a fee of about {rate}% {currency},currently available {available} {currency}',

    400210: 'Payment Method',
    400211: 'Select up to 3',
    400212: 'No payment method at the moment',
    400213: 'Choose the payment time limit',
    400214: 'Enter the content',
    400215: 'Trading User Conditions',
    400216: 'Increasing the trading user limit will reduce the chances of your ad showing',
    400217: 'Complete registration',
    400218: 'days later',
    400219: 'Launch Now',

    400220: 'Launch Later',
    400221: 'Risk Disclosure',
    400222: 'You must enter a number',
    400223: 'Choose the payment method',
    400224: 'Enter floating price',
    400225: 'Enter fixed price',
    400226: 'The price has deviated from the market {num}%,continuing to publish will be risky, please confirm whether to continue to publish ads',
    400227: 'Maximum single limit must be greater than the minimum single limit',
    400228: 'Maximum single limit should not be higher than {num} {fiat}',
    400229: 'Minimum single transaction limit shall not be less than {num} {fiat}',

    400230: 'Minimum single-order limit must be less than the maximum single-order limit',
    400231: 'Maximum transaction amount must not be higher than {num} {currency}',
    400232: 'Publishing Successful',
    400233: 'P2P trading is not open to authorized wallet users at this time, please use your SuperEx account to log in and trade P2P',
    400234: 'DAO Network',
    400235: 'Roadmap',
    400236: 'Invite',
    400237: 'Help Center',
    400238: 'Airdrop Base',
    400239: 'Mainnet Explore',
    400240: 'Testnet Explore',
    400241: 'Developer Docs',
    400242: 'Events Center',
    400243: 'By using the SuperEx sites and/or maintaining your account, you agree to our updated <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/11479008598553-Terms-of-Service">Terms of Service</a>, <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/11478863478809-Privacy-Statement">Privacy Statement</a> and <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/20761721369241">Cookie Policy</a>. By clicking "X," you acknowledge you have read and agreed to the aforementioned Terms and Policies. ',
    400244: 'Buy Tether | Get USDT | SuperEx P2P',
    400245: 'Task Center',
    400246: 'Currency',
    400247: 'Receive',
    400248: 'Expenses',
    400249: 'Available',

    400250: 'Pay',
    400251: 'Order details',
    400252: 'Amount (including transaction fees)',
    400253: 'Arrival time',
    400254: '≈{time} minutes',
    400255: 'Notice',
    400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: 'I have read and agree to the disclaimer',
    400261: 'How to buy crypto on SuperEx ?',
    400262: 'How to Sell Cryptocurrency on SuperEx？',
    400263: 'Place your crypto order',
    400264: 'Choose the fiat currency you want to pay with and the crypto you want to buy. Enter the amount you want to spend.',
    400265: 'Verify your identity',
    400266: 'Complete KYC verification on SuperEx and the payment provider to complete the payment.',
    400267: 'Check your order details',
    400268: 'Check your deposit history to view the status of your order.',
    400269: '1. What payment methods can I use to buy cryptocurrency?',

    400270: 'SuperEx currently supports VISA, Mastercard, Apple Pay, Google Pay, and other payment methods. The supported third-party service providers include Mercuryo and alchemypay.',
    400271: '2. What cryptocurrencies can I purchase?',
    400272: 'SuperEx supports mainstream cryptocurrencies such as BTC, ETH, USDT, LTC, USDC, XRP, BCH, CRV, and TRX.',
    400273: '3. Does SuperEx monitor the quality and prices of the supported third-party service providers?',
    400274: 'SuperEx has carefully selected a number of service providers with trusted industry reputation, popularity, and security to provide users with streamlined services.',
    400275: '4. What fees do I have to pay to buy cryptocurrency?',
    400276: 'You pay a fee to the third-party service provider and an on-chain transfer fee. SuperEx does not charge any fees.',
    400277: '5. How long does it take to receive the cryptocurrency after payment?',
    400278: 'After your payment is completed on the third-party service provider platform, your cryptocurrency will be deposited into your spot account on SuperEx in around 2–10 minutes.',
    400279: '6. What if I encounter problems during the purchase process?',

    400280: 'Contact customer support if you encounter any problems during the transaction process. If you have not received the cryptocurrency after payment is completed, contact the third-party service provider to check the order details (this is usually the most efficient method). Due to the IP of your current region or certain policy reasons, you will have to select human verification.',
    400281: 'Single transaction limit:',
    400282: 'Enter volume',
    400283: 'Payment provider',
    400284: 'Get',
    400285: 'Insufficient Balance',
    400286: "You will leave SuperEx and jump to <span>{name}</span>, a licensed financial institution that provides card payment processing capabilities as a third party. Any risk events that occur during use will be borne by <span>{name}</span>. Please read and agree to <a class= 'pointer' target='_blank' href={server}>Service Agreement</a> before using its services.If you have any questions about credit card deposits, please contact <a class='pointer' target='_blank' href={Customer}>{name} Customer Service</a> for more details.",
    400287: 'Disclaimer',
    400289: 'Spot Account Availiable',
    400290: 'Confirm',
    400291: 'ET Zone',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: 'Buy Crypto',
    400295:'Open Safari to use Apple Pay',
    400288: 'Finance',
    400292:'Financial account',




    410000:'SCS Airdrop',
    410001:'Agents',

    410002:'Finance',
    100756: 'Earn',
    100219: 'Activities',
    101154: 'Subscription History',


    122000:"Global DAO Network and Social Media Links",
    122001:"Invite Friends to Get Rewards",
    122002:"Development Plan of SuperEx",
    122003:"Solutions to Common Problems Encountered During Use",
    122004:"Complete Tasks to Get Rewards",
    122005:"Introduction to ET",
    122006:"Introduction to SuperEx",
    122007:"Rebate for Agent Referral",
    122008:"Low Fee on Purchasing Cryptocurrency",
    122009:"Quick Cryptocurrency Purchases",
    122010:"Cryptocurrency Trading Market",
    122011:"Free Market & Main Market",
    122012:"Futures Trading Market",
    122013:"IEO Platform for High-Value Projects",
    122014:"Saving money and managing money to earn income",
    122015:"Ultra-Low Price Shopping",
    122016:"Hub for Exciting Activities",
    122017:"Get Ahead in the BTC Ecosystem with New Coins",
    122018:"SCS Airdrop Task Center",
    122019:"Official Website of SCS Chain",
    122020:"SCS Chain Browser",
    122021:"SCS Chain Testnet Browser",
    122022:"Developer Documentation",
    122023:"Browse the latest news",
    122024:"Get a quick overview of the latest events",
    122025:"Check SuperEx on Youtube",
    122026:"Learn everything about blockchain",
    122027:"Open mystery box with your friends",
    122028: 'Mystery Box',
    

    //合约积分
    9100007: 'Trading Points',
    9100031: 'Trading Points Activity Center',

    9100032: 'Quantitative Funds',
    9100033: 'Professional Asset Management',

    8100000: 'User Feedback',
    8000153: 'List Tokens',
    8000154: 'BTC Protocol Markets',
    6000072: 'Free Market Order',
    6000073: 'Free Market Account',

    9100162: 'API Management',
    8100013: 'Official Verification Channel',
    8100014: 'Sell Channel',

    8100015: 'Media Toolkit',
    'target_1': 'Spot',
    'target_2': 'P2P Account',
    'target_3': '币本位合约账户',
    'target_4': 'USDⓢ-M Futures',
    'target_5': '超级合约账户',
    'target_6': 'Finance - Available Interest',

    ...enLocal,
    ...ac_en,
    ...enPoer
}
export default en;
