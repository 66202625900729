import enLocal from 'element-ui/lib/locale/lang/en' // element暂不支持乌克兰语，用英语代替
import ac_uk from "./activity/ac_uk";
import ukPoer from './languagePoer/uk'

const uk = {
    400000: 'Академія',
    400001: 'Новини',
    400002: 'Дані',
    400003: 'Відео',
    400004: 'Академія',
    400005: 'Увійти',
    400006: 'Реєстрація',
    400007: 'Гаманці',
    400008: 'Огляд гаманця',
    400009: 'Обліковий запис основної сторінки Spot',

    400010: 'P2P Акаунт',
    400011: 'Фьючерси',
    400012: 'Історія гаманця',
    400013: 'Ордер головної сторінки Spot',
    400014: 'Фьючерсні ордери',
    400015: 'Огляд',
    400016: 'Підтвердження особи',
    400017: 'Безпека облікового запису',
    400018: 'Реферал',
    400019: 'Налаштування',

    400020: 'Ваучерний центр',
    400021: 'Вийти',
    400022: '<em class="normal">{num}</em>відкладені сповіщення',
    400023: 'Очистити все',
    400024: 'Переглянути більше',
    400025: 'Записи не знайдено',
    400026: 'Скануйте, щоб завантажити програму',
    400027: 'Додаткові параметри завантаження',
    400028: 'Відомості про сповіщення',
    400029: 'Я розумію',

    400030: 'P2P',
    400031: 'Ринки',
    400032: 'Спот',
    400033: 'Фьючерси',
    400034: 'Super Start',
    400035: 'Адреса не існує',
    400036: 'Найбезпечніша у світі платформа для торгівлі цифровими активами！',
    400037: 'Про нас',
    400038: 'Умови',
    400039: 'Конфіденційність',

    400040: 'Оголошення',
    400041: '学习',
    400042: 'Центр початківців',
    400043: 'Спотова торгівля',
    400044: 'Фьючерси',
    400045: 'FAQ',
    400046: 'Послуги',
    400047: 'Комісії',
    400048: 'Правила',
    400049: 'Заява на лістинг',

    400050: 'Реферал',
    400051: "Зв'язатися з нами",
    400052: 'Центр підтримки',
    400053: 'Надіслати запит',
    400054: 'Бізнес-контакти',
    400055: 'Інституційна співпраця',
    400056: 'Мова та регіон',
    400057: 'Валюта',
    400058: 'Вибрати мову та регіон',
    400059: 'Виберіть курс обміну',

    400060: 'Переказ',
    400061: 'Внутрішні перекази SuperEx не стягують комісію',
    400062: 'Від',
    400063: 'до',
    400064: 'Пара',
    400065: 'Знижка комісії',
    400066: 'Схрещена',
    400067: 'Ізольована',
    400068: 'Спліт',
    400069: 'Баланс',

    400070: 'Криптовалюта',
    400071: 'Пошук',
    400072: 'Сума',
    400073: 'Доступно',
    400074: 'Всі',
    400075: 'Підтвердити переказ',
    400076: 'Відкрити',
    400077: 'Наступного разу',
    400078: 'Відкрити фьючерсний рахунок',
    400079: 'Ви ще не відкрили ф’ючерсний рахунок, тому трансфер тимчасово недоступний.',

    400080: '{timeout} c',
    400081: 'Надіслати код',
    400082: 'Всі криптовалюти',
    400083: 'Всі фіатні валюти',
    400084: 'Всі статуси',
    400085: "Необов'язкове поле",
    400086: 'Третя особа',
    400087: 'Довідник',
    400088: 'Замовлення',
    400089: 'Торговий центр P2P',

    400090: 'Центр підтримки P2P',
    400091: 'Метод оплати',
    400092: 'Розмістити новий ордер',
    400093: 'Мої ордери',
    400094: 'Більше',
    400095: 'Подати',
    400096: 'Скасувати',
    400097: 'Ви тимчасово не можете розміщувати оголошення',
    400098: 'Спочатку подайте заявку, щоб стати продавцем',
    400099: 'Пройдіть верифікацію',

    400100: 'Не верифіковано',
    400101: 'Ви не пройшли верифікацію особи',
    400102: 'Для безпеки вашого облікового запису з іншими користувачами, будь ласка, спочатку пройдіть верифікацію особи',
    400103: 'Придумайте нікнейм',
    400104: "Встановіть нікнейм вашого облікового запису. Рекомендується не використовувати своє справжнє ім'я. Довжина ніку - 2~10 символів.",
    400105: 'На жаль, зараз ви не можете подати заявку на отримання статусу продавця.',
    400106: 'Налаштовано успішно',
    400107: 'Переваги SuperEx P2P',
    400108: '0 Комісія',
    400109: 'У SuperEx P2P ви можете торгувати біткойнами та іншими цифровими валютами зручно та швидко, а сторона, яка розміщує замовлення, торгує без комісій.',

    400110: 'Гнучкі методи оплати',
    400111: 'SuperEx P2P дозволяє продавцям вільно обирати методи оплати.',
    400112: 'Торгуйте за кращими цінами',
    400113: 'SuperEx P2P дозволяє користувачам встановлювати власні ціни та обирати бажану ціну для купівлі та продажу криптовалюти.',
    400114: 'Безпечна торгівля криптовалютами',
    400115: 'SuperEx P2P використовує механізм виведення коштів T+n, суворе управління продавцями та перевірку великих даних для контролю ризиків задля перевірки підозрілих транзакцій, забезпечуючи безпеку активів платформи, щоб користувачі могли безпечно торгувати.',
    400116: "Об'єм {amount}",
    400117: 'Кількість транзакцій {num}%',
    400118: 'Ціна',
    400119: 'Термін для оплати',

    400120: 'Метод оплати продавця',
    400121: 'Умови транзакції',
    400122: 'Я бажаю оплатити',
    400123: 'Я отримаю',
    400124: 'Хочу продати',
    400125: 'Метод оплати',
    400126: 'Додайте метод оплати',
    400127: 'Додати метод оплати',
    400128: 'Орієнтовна ціна за одиницю {price}.',
    400129: 'Купити',

    400130: 'Продати',
    400131: 'Для захисту коштів буде запроваджено ліміт на зняття {limit} куплених активів',
    400132: 'Дізнатися більше',
    400133: 'OK',
    400134: 'Відповідно до країни вашого сертифіката та робочих характеристик платформи, будь ласка, перейдіть на свою місцеву валюту для транзакцій',
    400135: 'Límite de compra: {min}-{max} {fiat}',
    400136: 'Límite de venta: {min}-{max} {fiat}',
    400137: 'Продавці',
    400138: 'Ліміт / Кількість',
    400139: 'Торгувати',

    400140: 'Сума',
    400141: 'ЛІміт',
    400142: 'У процесі',
    400143: 'OK',
    400144: 'Будь ласка, пройдіть перевірку особи перед початком транзакції',
    400145: 'Кількість скасувань ваших ордерів сьогодні перевищила {num} рази, і ви не можете продовжувати торгівлю. Будь ласка, спробуйте ще раз після {time}.',
    400146: 'Ви досягли максимальної кількості відкладених ордерів ({num}), будь ласка, завершіть спочатку поточний ордер.',
    400147: 'Цей продавець досягнув максимальної кількості відкладених ордерів, будь ласка, спробуйте пізніше або скористайтеся іншим оголошенням.',
    400148: 'Ви не відповідаєте вимогам подавця, будь ласка, спробуйте інше оголошення.',
    400149: 'Вибрати',

    400150: 'Як працює P2P',
    400151: 'Купити криптовалюту',
    400152: 'Продати криптовалюту',
    400153: 'Розмістіть свій ордер',
    400154: 'Після розміщення ордеру активи продавця за цією угодою будуть управлятися SuperEx P2P.',
    400155: 'Відправте оплату продавцю',
    400156: 'Відправте гроші продавцю через спосіб оплати в інформації про транзакцію і натисніть на кнопку "Сплачено, повідомити продавця" на SuperEx P2P після завершення переказу',
    400157: 'Отримайте свою криптовалюту',
    400158: 'Після того, як продавець підтвердить отримання платежу, зарезервована валюта буде видана вам.',
    400159: 'Підтвердити оплату',

    400160: 'Перевірте історію транзакцій у своєму обліковому записі та переконайтеся, що ви отримали платіж, надісланий покупцем.',
    400161: 'Відправити криптовалюту',
    400162: 'Відправляйте криптовалюту покупцям на SuperEx P2P після підтвердження отримання оплати.',
    400163: 'Сума',
    400164: 'Введіть суму',
    400165: 'Фіатна валюта',
    400166: 'Всі методи оплати',
    400167: 'ID оголошення',
    400168: 'Підтвердити',
    400169: 'Ви впевнені, що хочете вимкнути це оголошення?',

    400170: '1. Користувачі, які знімають оголошення, не зможуть торгувати з вами через оголошення',
    400171: '2. Зниження рейтингу оголошення не впливає на ордер в процесі виконання',
    400172: 'Ви дійсно хочете видалити це оголошення?',
    400173: 'Редагувати',
    400174: 'Видалити',
    400175: 'Додано',
    400176: 'Вилучено',
    400177: 'Час створення',
    400178: 'Мінімальний ліміт',
    400179: 'Залишок коштів/сума ордеру',

    400180: 'Вартість ордеру',
    400181: 'Тип',
    400182: 'Успішний лістинг',
    400183: 'Всі типи',
    400184: 'Статус',
    400185: 'ID оголошення/валюта/дата',
    400186: 'Опрацьовується',
    400187: 'Відповідно до операційних специфікацій країни або регіону, де верифікован ваш акаунт SuperEx, та платформи, будь ласка, оберіть підтримувану законну валюту для здійснення транзакцій.',
    400188: 'Не відповідає вимогам продавця',
    400189: 'Видалено',

    400190: 'Успішно видалено',
    400191: 'Розмістити оголошення',
    400192: 'Редагувати оголошення',
    400193: 'Фіксована ціна',
    400194: 'Змінна ціна',
    400195: 'Відсоток коливання ціни',
    400196: 'будь ласка введіть',
    400197: 'Торгова ціна',
    400198: 'Ринкова базова ціна',
    400199: 'Встановлення плаваючого відсотка дозволяє купувати і продавати на відсоток вище або нижче фактичної ринкової базової ціни',

    400200: 'Фіксовані ціни не змінюються з коливаннями ринку',
    400201: 'Торговельні обмеження',
    400202: 'Введіть обсяг транзакції',
    400203: 'Кількість трейдів',
    400204: 'Мінімальний одноразовий ліміт',
    400205: 'Введіть мінімальний ліміт на одну транзакцію',
    400206: 'Максимальний одноразовий ліміт',
    400207: 'Введіть максимальний ліміт на одну транзакцію',
    400208: 'Макс',
    400209: 'За розміщення оголошення стягується комісія в розмірі близько {rate}% у {currency}, на даний момент доступно {available} {currency}.',

    400210: 'Метод оплати',
    400211: 'Оберіть до 3',
    400212: 'Наразі немає способу оплати',
    400213: 'Виберіть термін оплати',
    400214: 'Введіть вміст',
    400215: 'Умови користування',
    400216: 'Додавання обмежень для покупців зменшить шанси на показ вашого оголошення',
    400217: 'Завершіть реєстрацію',
    400218: 'Дні потому',
    400219: 'Почнеться зараз',

    400220: 'Почнеться пізніше',
    400221: 'Попередження про ризик',
    400222: 'Ви повинні ввести число',
    400223: 'Виберіть спосіб оплати',
    400224: 'Введіть змінну ціну',
    400225: 'Введіть фіксовану ціну',
    400226: 'Ціна відхилилася від ринкової на {num}%, продовження публікації буде ризикованим, будь ласка, підтвердіть, чи продовжувати публікувати оголошення?',
    400227: 'Максимальний разовий ліміт повинен бути більшим за мінімальний разовий ліміт',
    400228: 'Максимальний ліміт на одну транзакцію не може бути більшим, ніж {num} {fiat}.',
    400229: 'Мінімальний ліміт на одну транзакцію не може бути меншим, ніж {num} {fiat}.',

    400230: 'Мінімальний ліміт для одного замовлення має бути меншим за максимальний',
    400231: 'Максимальна сума транзакцій не повинна перевищувати {num} {currency}.',
    400232: 'Опубліковано успішно',
    400233: 'На даний момент торгівля P2P не відкрита для авторизованих гаманцем користувачів, будь ласка, використовуйте свій обліковий запис SuperEx для входу і торгівлі P2P',
    400234: 'Мережа DAO',
    400235: 'Roadmap',
    400236: 'Запросити друзів ',
    400237: 'Центр підтримки',
    400238: 'Центр Airdrop',
    400239: 'Переглянути в основній мережі',

    400240: 'Переглянути в тестовій мережі',
    400241: 'Документи для розробників',
    400242: 'Центр подій',
    400243: 'Використовуючи сайти SuperEx та/або зберігаючи свій обліковий запис, ви погоджуєтеся з нашими оновленими <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/uk-ua/articles/11564085700889">Умовами надання послуг</a>, <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/uk-ua/articles/11564025550361">Заява про конфіденційність</a>、 та <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/uk-ua/articles/20761492635801">Політикою щодо файлів cookie</a>. Натискаючи «X», ви підтверджуєте, що прочитали та погодилися з вищезазначеними Умовами та Політикою.',
    400244: 'Купити Tether | Отримати USDT | SuperEx P2P',
    400245: 'Центр завдань',
    400246: 'Валюта',
    400247: 'Отримати',
    400248: 'Витрати',
    400249: 'Доступно',

    400250: 'Сплата',
    400251: 'Деталі замовлення',
    400252: 'Сума (включаючи комісію за транзакцію)',
    400253: 'Час прибуття',
    400254: '≈{time} хвилин',
    400255: 'Поради',
    400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: 'Я прочитав і погоджуюся з відмовою від відповідальності',
    400261: 'Як купити криптовалюту на SuperEx?',
    400262: 'Як продати криптовалюту на SuperEx？',
    400263: 'Розмістіть замовлення на покупку криптовалюти',
    400264: 'Виберіть фіатну валюту, якою ви хочете заплатити, і криптовалюту, яку хочете купити. Введіть суму, яку ви хочете витратити.',
    400265: 'Підтвердіть свою особу',
    400266: 'Пройдіть перевірку KYC на SuperEx і у постачальника послуг, щоб завершити платіж',
    400267: 'Перевірте деталі вашого замовлення',
    400268: 'Перевірте історію депозитів, щоб переглянути статус вашого замовлення.',
    400269: '1. Які способи оплати я можу використовувати для купівлі криптовалюти?',

    400270: 'Наразі SuperEx підтримує VISA, Mastercard, Apple Pay, Google Pay та інші способи оплати. Підтримувані сторонні постачальники послуг включають Mercuryo і alchemypay.',
    400271: '2. Які криптовалюти я можу придбати?',
    400272: 'SuperEx підтримує основні криптовалюти, такі як BTC, ETH, USDT, LTC, USDC, XRP, BCH, CRV і TRX.',
    400273: '3. Чи контролює SuperEx якість і ціни підтримуваних сторонніх постачальників послуг?',
    400274: 'SuperEx ретельно відібрав ряд постачальників послуг з надійною галузевою репутацією, популярністю і безпекою, щоб надати користувачам спрощені послуги.',
    400275: '4. Які комісії потрібно сплатити, щоб купити криптовалюту?',
    400276: 'Ви сплачуєте комісію сторонньому постачальнику послуг і комісію за переказ в ланцюжку. SuperEx не стягує жодних комісій.',
    400277: '5. Скільки часу потрібно для отримання криптовалюти після оплати?',
    400278: 'Після завершення платежу на платформі стороннього постачальника послуг ваша криптовалюта буде зарахована на ваш спот-рахунок на SuperEx приблизно через 2-10 хвилин.',
    400279: '6. Що робити, якщо у мене виникнуть проблеми в процесі покупки?',

    400280: 'Зверніться до служби підтримки клієнтів, якщо у вас виникнуть будь-які проблеми в процесі транзакції. Якщо ви не отримали криптовалюту після завершення оплати, зверніться до стороннього постачальника послуг для перевірки деталей замовлення (зазвичай це найефективніший метод). Через IP-адресу вашого поточного регіону або певні політичні причини, вам доведеться вибрати перевірку людиною.',
    400281: 'Ліміт на одну транзакцію:',
    400282: 'Введіть об\'єм',
    400283: 'Платіжний провайдер',
    400284: 'Отримати',
    400285: 'Недостатній баланс',
    400286: "You will leave SuperEx and jump to <span>{name}</span>, a licensed financial institution that provides card payment processing capabilities as a third party. Any risk events that occur during use will be borne by <span>{name}</span>. Please read and agree to <a class= 'pointer' target='_blank' href={server}>Service Agreement</a> before using its services.If you have any questions about credit card deposits, please contact <a class='pointer' target='_blank' href={Customer}>{name} Customer Service</a> for more details.",
    400287: 'Відмова від відповідальності',
    400289: 'Доступний спот-рахунок',
    400290: 'Підтвердити',
    400291: 'Зона ET',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: 'Купити крипту',

    400295:'Будь ласка, відкрийте браузер safari для використання Applepay',
    400288: 'Фінанси',

    400292:'Фінансовий рахунок',

    410000:'SCS Airdrop',
    410001:'Агенти',

    410002:'Фінанси',
    100756: 'Заробити',
    100219: 'Діяльність',
    101154: 'Історія підписок',



    122000:"Глобальна мережа DAO та посилання на соціальні мережі",
    122001:"Запросіть друзів, щоб отримати винагороду",
    122002:"План розвитку SuperEx",
    122003:"Рішення поширених проблем, що виникають під час використання",
    122004:"Виконуйте завдання, щоб отримати винагороду",
    122005:"вступ до ET",
    122006:"Вступ до SuperEx",
    122007:"Винагорода за залучення агентів",
    122008:"Низька комісія за купівлю криптовалюти",
    122009:"Швидка купівля криптовалюти",
    122010:"Ринок торгівлі криптовалютою",
    122011:"Торгівля токенами на Free Market та Main Market",
    122012:"Ринок торгівлі ф'ючерсами",
    122013:"Платформа IEO для високовартісних проектів",
    122014:"Заощадження та управління грошима для отримання доходу",
    122015:"Шопінг за наднизькими цінами",
    122016:"Хаб для захоплюючих заходів",
    122017:"Просуньтесь в екосистемі BTC з новими монетами",
    122018:"SCS Airdrop Task Center",
    122019:"Офіційний сайт SCS Chain",
    122020:"Браузер SCS Chain",
    122021:"Тестовий браузер SCS Chain",
    122022:"Документація для розробника",
    122023:"Переглянути останні новини",
    122024:"Швидкий огляд останніх подій",
    122025:"Перевірити SuperEx на YouTube",
    122026:"Дізнатися все про блокчейн",
    122027:"Відкрийте таємничу скриньку з друзями",
    122028: 'Mystery Box',


    //合约积分
    9100007: 'Торгові бали',
    9100031: 'Центр активності торгових балів',

    9100032: 'Кількісні фонди',
    9100033: 'Професійне управління активами',

    8100000: 'Відгуки користувачів',
    8000153: 'Лістинг токенів',
    8000154: 'Ринки протоколу BTC',
    6000072: 'Ордери вільного ринку',
    6000073: 'Рахунок Free Market',

    9100162: 'Управління API',
    8100013: 'Офіційний канал перевірки',
    8100014: 'Канали продажу',

    8100015: 'Інструменти для медіа',
    'target_1': 'Спот',
    'target_2': 'P2P Акаунт',
    'target_3': '币本位合约账户',
    'target_4': 'USDⓢ-М',
    'target_5': '超级合约账户',
    'target_6': 'Фінанси - Доступні відсотки',

    ...enLocal,
    ...ac_uk,
    ...ukPoer
}
export default uk;
