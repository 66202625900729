import jaLocal from 'element-ui/lib/locale/lang/ja'
import ac_ja from "./activity/ac_ja";
import jaPoer from './languagePoer/ja'
const ja = {
    400000: 'カレッジ',
    400001: 'ニュース',
    400002: 'データ',
    400003: 'ビデオ',
    400004: '学習',
    400005: 'ログイン',
    400006: '新規登録',
    400007: 'ウォレット',
    400008: 'ウォレット概要',
    400009: '現物メインボードエリアのアカウント',

    400010: 'P2Pアカウント',
    400011: '先物アカウント',
    400012: 'ウォレット履歴',
    400013: '現物板区の注文書',
    400014: '先物注文',
    400015: '概要',
    400016: '認証',
    400017: 'アカウントセキュリティ',
    400018: 'コミッション',
    400019: '環境設定',

    400020: 'クーポンセンター',
    400021: 'ログアウト',
    400022: '<em class="normal">{num}</em> 新着通知',
    400023: 'すべてクリア',
    400024: 'もっと見る',
    400025: 'データなし',
    400026: 'スキャンしてダウンロード',
    400027: 'その他のダウンロード',
    400028: '通知の詳細',
    400029: '了解しました',

    400030: 'P2P',
    400031: '市況',
    400032: '現物',
    400033: '先物',
    400034: 'Super Start',
    400035: 'アドレスが存在しません',
    400036: '世界で最も信頼されているデジタル資産取引プラットフォーム',
    400037: '私たちについて',
    400038: '利用規約',
    400039: 'プライバシーポリシー',

    400040: 'アナウンスセンター',
    400041: '学习',
    400042: '初心者センター',
    400043: '現物取引',
    400044: '先物取引',
    400045: 'FAQ',
    400046: 'サービス',
    400047: 'レート標準',
    400048: '取引ルール',
    400049: 'コイン申請',

    400050: 'コミッション',
    400051: 'お問い合わせ',
    400052: 'ヘルプセンター',
    400053: 'ご要望',
    400054: 'ビジネスリエゾン',
    400055: 'ビジネス提携',
    400056: '言語と地域',
    400057: '為替レート',
    400058: '言語と地域を選択',
    400059: '為替レートを選択',

    400060: '出入金',
    400061: 'SuperEx内部は振込手数料が無料です',
    400062: 'から',
    400063: 'に/まで',
    400064: '通貨ペア',
    400065: '手数料を支払う',
    400066: 'クロス',
    400067: '分離',
    400068: '分割',
    400069: '残高',

    400070: '通貨',
    400071: '探す',
    400072: '利用可能',
    400073: '利用可能な資産',
    400074: 'すべて',
    400075: '出入金を確認する',
    400076: '開通する',
    400077: 'また今度',
    400078: '先物口座を開設する',
    400079: '先物口座を開設しておらず、一時的にコインを転送することはできません',

    400080: '{timeout}秒後に再送します',
    400081: '認証コードを送信',
    400082: 'すべての通貨',
    400083: 'すべての法定通貨',
    400084: 'すべての州',
    400085: '自己選択エリア',
    400086: '第三者',
    400087: 'ヘルプガイド',
    400088: '注文',
    400089: 'P2P マーチャント センター',

    400090: 'P2P ユーザーセンター',
    400091: '支払方法',
    400092: '新しい広告を投稿する',
    400093: '私の広告',
    400094: 'もっと',
    400095: '適用する',
    400096: 'キャンセル',
    400097: '一時的に広告を投稿できません',
    400098: 'まず広告主として申請してください',
    400099: '認証に行く',

    400100: 'まだ認定されていません',
    400101: '本人確認が完了していません',
    400102: '取引ユーザーとのアカウントのセキュリティのために、最初に身元認証を完了してください',
    400103: 'ニックネームを設定',
    400104: 'アカウントのニックネームを設定してください。本名は使用しないことをお勧めします',
    400105: '申し訳ありませんが、マーチャントになるための申請は一時的に利用できません。',
    400106: '設定に成功しました',
    400107: 'SuperEx P2P の利点',
    400108: '0 手数料',
    400109: 'SuperEx P2P では、ビットコインやその他のデジタル通貨を便利かつ迅速に取引でき、発注者は手数料ゼロで取引できます。',

    400110: '柔軟な支払い方法',
    400111: 'SuperEx P2P では、売り手は支払い方法を自由に選択できます。',
    400112: 'より良い価格で取引する',
    400113: 'SuperEx P2P により、ユーザーは独自の価格を設定し、好みの価格を選択してデジタル通貨を売買できます。',
    400114: 'ビットコインなどのデジタル通貨を安全に取引',
    400115: 'SuperEx P2P は、T+n 現金引き出しメカニズムを採用し、加盟店を厳格に管理し、疑わしい取引をリスク管理ビッグデータで選別して、プラットフォーム資産の安全性とユーザーが安全に取引できるようにします。',
    400116: '{amount} 取引量',
    400117: '{num} %取引レート',
    400118: '価格',
    400119: '支払い期限',

    400120: '売り手の支払い方法',
    400121: '取引条件',
    400122: '払いたい',
    400123: '私は受け取ります',
    400124: '売りたい',
    400125: '支払方法',
    400126: '支払い方法を設定する',
    400127: 'お支払い方法を追加',
    400128: '参考単価{price}',
    400129: '買う',

    400130: '売る',
    400131: '資金の安全を守るため、購入した資産には{limit}出金制限が実装されます',
    400132: '詳しくはこちら',
    400133: 'そうですか',
    400134: '証明書の国とプラットフォームの動作仕様に応じて、取引のために現地通貨に切り替えてください',
    400135: '購入制限：{min}-{max} {fiat}',
    400136: '販売枠: {min}-{max} {fiat}',
    400137: '広告主',
    400138: '指値/利用可能',
    400139: '取引',

    400140: '利用可能',
    400141: '指値',
    400142: '対処する',
    400143: 'はい',
    400144: '取引を開始する前に、本人確認を完了してください',
    400145: '本日の注文キャンセルが {num} 回を超えたため、取引を続行できません。{time} の後にもう一度お試しください',
    400146: '保留中の注文が上限 ({num}) に達しました。まず進行中の注文を完了してください。',
    400147: '広告主が保留中のオーダーの上限に達しました。後で操作するか、別の広告を試してください。',
    400148: '広告主の要件を満たしていません。別の広告をお試しください。',
    400149: '選択してください',

    400150: 'P2P の仕組み',
    400151: 'デジタル通貨を購入する',
    400152: 'デジタル通貨を売る',
    400153: '注文する',
    400154: '注文後、この取引の売り手の資産は SuperEx P2P によってエスクローされます。',
    400155: '売り手に支払う',
    400156: '取引情報の決済方法で出品者に送金し、送金完了後、SuperEx P2Pの「支払い済み、出品者に通知する」をクリック',
    400157: 'デジタル通貨を手に入れよう',
    400158: '売り手が支払いの受領を確認すると、エスクローされた通貨が解放されます。',
    400159: '支払いを確認する',

    400160: 'アカウントの取引履歴を確認し、購入者からお金を受け取ったことを確認してください。',
    400161: 'デジタル通貨のリリース',
    400162: '支払いの受領を確認した後、SuperEx P2P で購入者にデジタル通貨を発行します。',
    400163: '総額',
    400164: '金額を入力してください',
    400165: 'フランスの通貨',
    400166: 'すべての支払い方法',
    400167: '広告 ID',
    400168: 'もちろん',
    400169: '広告をキャンセルしてもよろしいですか？',

    400170: '1.広告を削除したユーザーは、広告を通じてあなたと取引することができなくなります',
    400171: '2. 広告を削除しても、進行中の注文には影響しません',
    400172: 'この広告を削除してもよろしいですか?',
    400173: '編集',
    400174: '消去',
    400175: 'アクティブ化',
    400176: 'キャンセル',
    400177: '作成時間',
    400178: '最小額',
    400179: '残り/発注量',

    400180: '手数料価格',
    400181: 'タイプ',
    400182: '正常に起動しました',
    400183: 'いろんなタイプ',
    400184: '州',
    400185: '広告 ID/通貨/日付',
    400186: '操作します',
    400187: 'SuperEx ID が認証されている国または地域の動作仕様とプラットフォームに従って、サポートされている取引用の法定通貨を選択してください。',
    400188: '広告主の要件を満たしていません',
    400189: '削除しました',

    400190: '正常に削除されました',
    400191: 'リリース',
    400192: '広告を編集',
    400193: '固定価格',
    400194: '変動価格',
    400195: '価格変動率',
    400196: '入力してください',
    400197: '取引の価格',
    400198: '市場参考価格',
    400199: '変動比率を設定すると、リアルタイムの市場参照価格の上下に一定の割合で取引できます',

    400200: '固定価格は市場の変動によって変化しません',
    400201: '取引限度額',
    400202: '取引数量を入力してください',
    400203: '取引回数',
    400204: '最小シングル リミット',
    400205: '1回の取引の最低限度額を入力してください',
    400206: '最大シングルリミット',
    400207: '1 回の取引の上限を入力してください',
    400208: '最大',
    400209: 'この広告に必要な取引手数料は約 {rate}% {currency}、現在利用可能な {available} {currency} です。',

    400210: '支払方法',
    400211: '3つまで選択',
    400212: 'お支払い方法はまだありません',
    400213: '支払い期限を選択してください',
    400214: '内容を入力してください',
    400215: '取引ユーザー条件',
    400216: '取引ユーザーの制限を増やすと、広告が表示される可能性が低くなります',
    400217: '登録完了',
    400218: '日後',
    400219: '今すぐオンラインに',

    400220: '後で投稿する',
    400221: 'リスクのヒント',
    400222: '番号を入力する必要があります',
    400223: 'お支払い方法を選択してください',
    400224: '変動比率を入力してください',
    400225: '固定価格を入力してください',
    400226: '価格が市場  {num}% から外れており、掲載を続けるにはリスクが伴います。広告を掲載し続けるかどうかを確認してください。',
    400227: '単一トランザクションの最大制限は、単一トランザクションの最小制限よりも大きくする必要があります',
    400228: '1回の取引限度額は{num} {fiat}を超えてはならない',
    400229: '1回の最低取引限度額は{num} {fiat}を下回ってはいけません',

    400230: '1 回のトランザクションの最小制限は、1 回のトランザクションの最大制限よりも小さくする必要があります',
    400231: '最大取引数量は{num} {currency}を超えてはならない',
    400232: '正常に公開されました',
    400233: 'P2P トランザクションは一時的にウォレットの承認されたユーザーに公開されていません。P2P トランザクションを行うには SuperEx アカウントでログインしてください',
    400234: 'DAO ネットワーク',
    400235: 'Roadmap',
    400236: 'お友達紹介',
    400237: 'ヘルプセンター',
    400238: 'エアドロップ',
    400239: 'メインネットブラウザ',

    400240: 'テストネットブラウザ',
    400241: '開発者向けドキュメント',
    400242: 'イベントセンター',
    400243:'SuperExのウェブサイトの利用およびアカウントの維持は、弊社の更新された<a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/11479008598553-Terms-of-Service">「利用規約」</a>、<a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/11478863478809-Privacy-Statement">「プライバシー声明」</a>、および<a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/en-001/articles/11478863478809-Privacy-Statement">「Cookieポリシー」</a>への同意を意味します。[X]をクリックすることにより、上記の条項とポリシーをお読みいただき、同意されたことを確認します。',
    400244: 'Tetherの購入｜USDTの取得｜SuperEx P 2 P',
    400245: 'ミッションセンター',
    400246: '通貨',
    400247: '受け取る',
    400248: '支出',
    400249: '利用可能な残高',

    400250: '支払い',
    400251: '注文詳細',
    400252: '金額（取引手数料含む）',
    400253: '到着時刻',
    400254: '≈{time} minutes',
    400255: 'ヒント',
    400256: '1.您将离开SUPEREX并进入第三方AlchemyPay网页以完成您的订单。',
    400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: '免責事項を読み、同意します',
    400261: 'SuperEx で暗号通貨を購入するにはどうすればよいですか?',
    400262: 'SuperEx で暗号通貨を販売する方法？',
    400263: '暗号通貨の注文をする',
    400264: '支払いたい法定通貨と購入したい暗号通貨を選択します。 使いたい金額を入力します。',
    400265: '身元を確認する',
    400266: 'SuperEx と決済プロバイダーで KYC 検証を完了して、支払いを完了します。',
    400267: '注文内容を確認する',
    400268: '入金履歴を確認して、注文のステータスを確認してください。',
    400269: '1. 暗号通貨の購入にはどのような支払い方法を使用できますか?',

    400270: 'SuperEx は現在、VISA、Mastercard、Apple Pay、Google Pay およびその他の支払い方法をサポートしています。 サポートされているサードパーティ サービス プロバイダーには、Mercuryo、alchemypay が含まれます。',
    400271: '2. どの仮想通貨を購入できますか?',
    400272: 'SuperEx は、BTC、ETH、USDT、LTC、USDC、XRP、BCH、CRV、TRX およびその他の主流の暗号通貨をサポートしています。',
    400273: '3. SuperEx は、サポートされているサードパーティ サービス プロバイダーの品質と価格を監視していますか?',
    400274: 'SuperEx は、ユーザーにプロセス指向のサービスを提供するために、信頼できる業界での評判、人気、セキュリティを備えたサービス プロバイダーのグループを慎重に選択しました。',
    400275: '4. 暗号通貨の購入手数料はいくらですか?',
    400276: 'サードパーティのサービスプロバイダーに料金とオンチェーン転送を支払います。 SuperEx では手数料はかかりません。',
    400277: '5. 支払い後、暗号通貨を受け取るまでにどのくらい時間がかかりますか?',
    400278: 'サードパーティのサービス プロバイダー プラットフォームで支払いを完了すると、約 2 ～ 10 分以内に暗号通貨が SuperEx スポット アカウントに入金されます。',
    400279: '6. 購入プロセス中に問題が発生した場合はどうすればよいですか?',

    400280: '取引中に問題が発生した場合は、カスタマーサポートまでご連絡ください。 支払い完了後に暗号通貨を受け取っていない場合は、サードパーティのサービスプロバイダーに連絡して注文の詳細を確認してください (通常、これが最も効率的な方法です)。 現在の地域の IP またはいくつかのポリシー上の理由により、手動検証を選択する必要があります。',
    400281: '1回のお取引限度額：',
    400282: '数量を入力してください',
    400283: '支払いチャネル',
    400284: '得る',
    400285: '残高が足りない',
    400286: "す。これは、サードパーティとして銀行カードによる支払い機能を提供する認可を受けた金融機関です。 使用中に発生した危険事象は<span>{name}</span>が負担します。 <span>{name}</span>サービスをご利用になる前に、<a class='pointer' target='_blank' href={server}>サービス利用規約</a>をよくお読みいただき、同意してください。クレジット カード デポジットについてご質問がある場合は、詳細について <a class='pointer' target='_blank' href={Customer}>{name} カスタマー サービス</a> までお問い合わせください。",
    400287: '免責事項',
    400289: '現物アカウントが利用可能',
    400290: '確認',
    400291: 'ET ゾーン',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: '暗号資産を購入',
    400295:'Applepayを使用するにはSafariブラウザを開いてください',
    400288: 'ファイナンス',
    400292:'金融口座',


    410000:'SCS エアドロップ',
    410001:'エージェント',

    410002:'金融ビジネス',
    100756: 'ファイナンシャル',
    100219: 'イベント',
    101154: '購読履歴',



    122000:"グローバルDAOネットワークとソーシャルメディアリンク",
    122001:"友達を招待して報酬を獲得",
    122002:"SuperExの開発計画",
    122003:"使用中に遭遇する一般的な問題の解決",
    122004:"任務を完了して報酬を獲得",
    122005:"ETの紹介",
    122006:"SuperExの紹介",
    122007:"代理店の紹介でリベート",
    122008:"低手数料での暗号通貨購入",
    122009:"便利な暗号通貨購入",
    122010:"暗号通貨取引市場",
    122011:"フリーマーケットとメインマーケットでのトークン取引",
    122012:"先物取引市場",
    122013:"高価値プロジェクトのIEOプラットフォーム",
    122014:"貯金と資産管理で収入を得る",
    122015:"超低価格ショッピング",
    122016:"エキサイティングな活動のハブ",
    122017:"BTCエコシステムで新しいコインを先取り",
    122018:"SCSエアドロップタスクセンター",
    122019:"SCSチェーン公式ウェブサイト",
    122020:"SCSチェーンブラウザ",
    122021:"SCSチェーンテストネットブラウザ",
    122022:"開発者ドキュメント",
    122023:"最新ニュースをブラウズ",
    122024:"最新イベントの概要をすばやく把握",
    122025:"YouTubeでSuperExをチェック",
    122026:"ブロックチェーンについてすべて学ぶ",
    122027:"友達とサプライズオープンボックス",
    122028: '謎の箱',

    //合约积分
    9100007: '取引ポイント',
    9100031: '取引ポイント活動センター',

    9100032: '計量ファンド',
    9100033: '専門的な富裕管理',

    8100000: 'ユーザーフィードバック',
    8000153: '自由なコイン上場',
    8000154: 'BTCプロトコル市場',
    6000072: '現物自由区の注文書',
    6000073: '現物自由地域のアカウント',

    9100162: 'API 管理',
    8100013: '公式認証チャンネル',
    8100014: '販売チャネル',

    8100015: 'メディアキット',
    'target_1': '現物口座',
    'target_2': 'P2Pアカウント',
    'target_3': '币本位合约账户',
    'target_4': 'USDⓢ-M先物',
    'target_5': '超级合约账户',
    'target_6': '財務 - 利用可能な金利',

    ...ac_ja,
    ...jaLocal,
    ...jaPoer
}
export default ja;
